export const Section4 = () => {
  return (
    <section className="flex w-full text-black pt-[90px] justify-center bg-[#F3F4F6] pb-[90px]">
      <div className="flex flex-row flex-wrap justify-center max-w-[60rem] w-full text-center justify-between gap-4 px-4 ">
        <div className="flex flex-col items-center bg-white p-4 w-[47%] xl:max-w-[23%] lg:max-w-[23%] md:max-w-[23%] sm:max-w-[47%] rounded-lg ">
          <img
            className="w-8 mb-4"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/37dab1e6-e1df-457e-0987-322f8b503900/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/37dab1e6-e1df-457e-0987-322f8b503900/public"
          />
          <strong>
            SUPORTE AO <br /> JOGADOR 24/7
          </strong>
        </div>

        <div className="flex flex-col items-center bg-white p-4 max-w-[47%] xl:max-w-[23%] lg:max-w-[23%] md:max-w-[23%] sm:max-w-[47%] w-full rounded-lg">
          <img
            className="w-8 mb-4"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/a5b21fe0-1c6d-46e4-e130-32cdf690dd00/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/a5b21fe0-1c6d-46e4-e130-32cdf690dd00/public"
          />
          <strong>
            PAGAMENTOS <br /> EM PIX
          </strong>
        </div>

        <div className="flex flex-col items-center bg-white p-4 max-w-[47%] xl:max-w-[23%] lg:max-w-[23%] md:max-w-[23%] sm:max-w-[47%] w-full rounded-lg">
          <img
            className="w-8 mb-4"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c1e0c7b1-b099-460d-1b03-5cc157c73500/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c1e0c7b1-b099-460d-1b03-5cc157c73500/public"
          />
          <strong>
            800.000 EVENTOS <br /> AO VIVO POR ANO
          </strong>
        </div>

        <div className="flex flex-col items-center bg-white p-4 max-w-[47%] xl:max-w-[23%] lg:max-w-[23%] md:max-w-[23%] sm:max-w-[47%] w-full rounded-lg">
          <img
            className="w-8 mb-4"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b6cd63cc-ccd0-42c0-ee8c-e054e5a35500/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b6cd63cc-ccd0-42c0-ee8c-e054e5a35500/public"
          />
          <strong>
            REGISTRO E <br /> VERIFICAÇÕES FÁCEIS
          </strong>
        </div>
      </div>
    </section>
  );
};
