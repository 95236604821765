import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

export function Timer({
  expiryTimestamp,
  playerRemaining,
}: {
  expiryTimestamp: Date;
  playerRemaining: number;
}) {
  const [dateArray, setDateArray] = useState([] as number[]);
  const [sum, setSum] = useState(0);

  const { seconds } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  useEffect(() => {
    if (dateArray.length < 10) {
      let dataArrayPreviousindex: number;
      let newValue: number;

      if (dateArray.length > 0) {
        dataArrayPreviousindex = dateArray.length - 1;

        newValue =
          dateArray[dataArrayPreviousindex] + Math.floor(Math.random() * 4) + 4;

        if (newValue < 60) setDateArray([...dateArray, newValue]);
      } else if (dateArray.length <= 0) {
        newValue = Math.floor(Math.random() * 4) + 4;

        setDateArray([...dateArray, newValue]);
      }
    }
  }, [seconds]);

  useEffect(() => {
    if (dateArray.length) {
      const timeMatch = dateArray.find((index) => seconds === index);

      if (timeMatch) {
        setSum(sum + 1);
      }
    }
  }, [dateArray, seconds]);

  const currentPlayers = playerRemaining - sum;

  return (
    <span
      className={`text-5xl ${currentPlayers < 30 && "text-red-200"} ${
        currentPlayers < 15 && "text-red-400"
      } ${currentPlayers <= 15 && "text-red-600"}`}
    >
      {currentPlayers >= 0 ? currentPlayers.toString() : 0}
    </span>
  );
}
