export const Section3 = () => {
  return (
    <section className="text-black flex flex-col items-center w-full bg-[#F3F4F6] text-[#1D1D29]">
      <h2 className="w-full max-w-[80%] xl:max-w-[30%] lg:max-w-[80%] md:max-w-[80%] sm:max-w-[80%] text-[1.75rem] xl:text-[2.75rem] lg:text-[2.75rem] md:text-[2.75rem] sm:text-[2.75rem] leading-8 xl:leading-10 lg:leading-8 md:leading-8 sm:leading-8 font-extrabold text-center text-[#0075CD] mb-[60px]">
        POR QUE ESCOLHER A CASSINOPIX?
      </h2>

      <div className="flex flex-row flex-wrap p-8 justify-center max-w-[60rem] w-full text-center justify-between gap-y-6">
        <div className="flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] ">
          <img
            className="rounded-lg w-full h-full "
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b96ae6a6-51ea-469e-e11d-5caa3e3feb00/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b96ae6a6-51ea-469e-e11d-5caa3e3feb00/public"
          />

          <strong className="mt-4 uppercase">Rodadas grátis</strong>
        </div>

        <div className="flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] ">
          <img
            className="rounded-lg w-full"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c3e50106-79d0-4001-5b68-5bcce6b8ee00/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c3e50106-79d0-4001-5b68-5bcce6b8ee00/public"
          />

          <strong className="mt-4 uppercase">Sorteios todo dia</strong>
        </div>

        <div className="flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] ">
          <img
            className="rounded-lg w-full"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/60598158-21a8-4cda-1b28-84e98cf8d700/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/60598158-21a8-4cda-1b28-84e98cf8d700/public"
          />

          <strong className="mt-4 uppercase">Saques instantâneos</strong>
        </div>

        <div className="flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] ">
          <img
            className="rounded-lg w-full"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/df9d690a-f300-4580-92d9-b28cec951100/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/df9d690a-f300-4580-92d9-b28cec951100/public"
          />
          <strong className="mt-4 uppercase">
            As melhores odds do mercado
          </strong>
        </div>
      </div>
    </section>
  );
};
