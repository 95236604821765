export const Footer = () => {
  return (
    <footer className="flex flex-col items-center w-full pt-12 bg-[#1D1D29]">
      <div className="flex flex-col max-w-[60rem] w-full text-center">
        <div className="flex justify-center items-center  pb-8 px-4">
          <p className="w-full text-[0.7rem] max-w-[60%]">
            Ao usar este site, você concorda com os termos, que podem ser
            alterados sem aviso. O conteúdo é propriedade da empresa e protegido
            por lei. A empresa não se responsabiliza por danos resultantes do
            uso. Em caso de dúvidas, contate-nos CNPJ: 51.936.110/0001-09.
          </p>

          <span className="w-[1px] h-full bg-[#515171] mx-8 my-8" />

          <div className="flex flex-col items-center">
            <img
              src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/0cae3a0f-73f3-48d8-28b0-933ac7e0ed00/public"
              alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/0cae3a0f-73f3-48d8-28b0-933ac7e0ed00/public"
              className="w-full max-w-[269px] max-h-[80px] h-full"
            />
            <p className="text-[0.7rem] font-semibold mt-3">
              Todos os direitos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
