import React, { useState } from "react";
import { ReactNode, createContext } from "react";

interface AppContextProps {
  timerState: Date;
}

export const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const currentDate = new Date();
  const updatedDate = currentDate.setHours(currentDate.getSeconds() + 60);

  const [timerState, setTimerState] = useState(new Date(updatedDate));

  return (
    <AppContext.Provider value={{ timerState }}>{children}</AppContext.Provider>
  );
};
