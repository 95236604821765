import { useState } from "react";

export const Section2 = () => {
  const [imageModalIsOpen, setImagesModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleViewimage = (image: string) => {
    setImagesModalIsOpen(true);
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setImagesModalIsOpen(false);
    setSelectedImage("");
  };

  const offerUrl =
    "https://go.aff.7k-partners.com/vfihopkm?utm_campaign=twitter-lp-02";

  return (
    <section className="flex flex-col items-center w-full bg-[#F3F4F6] pt-[90px] text-[#1D1D29]">
      {imageModalIsOpen && selectedImage.length && (
        <div className="flex absolute top-0 left-0 w-screen h-screen justify-center items-center bg-black/50 z-[99]">
          <div className="relative">
            <button
              className="absolute h-10 w-10 rounded-full bg-red-600 text-white right-[-20px] top-[-20px] hover:brightness-110"
              onClick={handleCloseModal}
            >
              X
            </button>
            <img
              className="max-h-[65vh]"
              src={selectedImage}
              alt={selectedImage}
            />
          </div>
        </div>
      )}

      <div className="flex flex-col items-center max-w-[60rem] text-center">
        <h2 className="w-full max-w-[80%] text-[1.75rem] xl:text-[2.75rem] lg:text-[2.75rem] md:text-[2.75rem] sm:text-[2.75rem] leading-8 xl:leading-10 lg:leading-8 md:leading-8 sm:leading-8 font-extrabold text-center text-[#0075CD] mb-[60px]">
          A PLATAFORMA QUE MAIS CRESCE NO BRASIL
        </h2>

        <p className="max-w-[80%] text-[1rem] xl:text-[1.2rem] lg:text-[1.2em] md:text-[1.15rem] sm:text-[1rem] mb-10 font-semibold">
          São mais de 3 milhões de jogadores espalhados em todos os cantos do
          país e mais de R$ 2 milhões de prêmios em iphones, bancas, notebooks,
          carros e motos para nossos jogadores.
        </p>

        <p className="max-w-[80%] text-[1rem] xl:text-[1.25rem] lg:text-[1.2rem] md:text-[1.15rem] sm:text-[1rem] mb-10 font-semibold">
          Fora que já aparecemos nos maiores portais de notícia do país,
          incluindo UOL, BAND, Correio Braziliense e Estado de Minas.
        </p>

        <a href={offerUrl} id="offer">
          <button className="mt-4 text-center cursor-pointer uppercase transition-all bg-[#0075CD] text-white font-extrabold min-h-[3.5rem] w-[16.3125rem] rounded-[10px] hover:brightness-110 mb-[40px]">
            RESGATE SEU BÔNUS
          </button>
        </a>

        <div className="flex flex-row flex-wrap p-8 justify-center max-w-[60rem] w-full text-center justify-between gap-y-6">
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/01c955ac-daed-4131-399e-911166f92100/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/01c955ac-daed-4131-399e-911166f92100/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/01c955ac-daed-4131-399e-911166f92100/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b6f413e-7232-4d32-30a7-70431c75d600/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b6f413e-7232-4d32-30a7-70431c75d600/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b6f413e-7232-4d32-30a7-70431c75d600/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c2cc4762-af0b-4238-089e-3c51550c1900/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c2cc4762-af0b-4238-089e-3c51550c1900/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/c2cc4762-af0b-4238-089e-3c51550c1900/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/eb7e0137-81c5-4bed-e161-bff718058600/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/eb7e0137-81c5-4bed-e161-bff718058600/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/eb7e0137-81c5-4bed-e161-bff718058600/public"
              )
            }
          />
        </div>

        <p className="max-w-[80%] text-[1rem] xl:text-[1.25rem] lg:text-[1.2rem] md:text-[1.15rem] sm:text-[1rem] mb-8 font-semibold">
          E já fechamos parcerias com os maiores influenciadores e artistas do
          país, como Maumau, Mc Ryan SP, Oruam, Chrys Dias, Calcinha Preta,
          Gordão da XJ e muitos outros.
        </p>

        <div className="flex flex-row flex-wrap p-8 justify-center max-w-[60rem] w-full text-center justify-between gap-y-6 mb-[90px]">
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/53c1e70f-6a91-4ee2-8dc2-6b1754f39600/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/53c1e70f-6a91-4ee2-8dc2-6b1754f39600/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/53c1e70f-6a91-4ee2-8dc2-6b1754f39600/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/48ab8b42-c2d2-40f0-7c82-48b107f64900/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/48ab8b42-c2d2-40f0-7c82-48b107f64900/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/48ab8b42-c2d2-40f0-7c82-48b107f64900/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/731bb729-7329-478b-82ad-b6abd0231400/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/731bb729-7329-478b-82ad-b6abd0231400/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/731bb729-7329-478b-82ad-b6abd0231400/public"
              )
            }
          />
          <img
            className=" flex flex-col items-center w-[48%] xl:w-[11vw] lg:w-[18vw] md:w-[22vw] sm:w-[22vw] cursor-pointer rounded-lg"
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/688ade82-c514-4f11-c5c8-6cfce9106f00/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/688ade82-c514-4f11-c5c8-6cfce9106f00/public"
            onClick={() =>
              handleViewimage(
                "https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/688ade82-c514-4f11-c5c8-6cfce9106f00/public"
              )
            }
          />
        </div>

        <a href={offerUrl} id="offer" className="mb-[90px]">
          <button className="mt-4 text-center cursor-pointer uppercase transition-all bg-[#0075CD] text-white font-extrabold min-h-[3.5rem] w-[16.3125rem] rounded-[10px] hover:brightness-110">
            RESGATE SEU BÔNUS
          </button>
        </a>
      </div>
    </section>
  );
};
