// Componments
import { Section1 } from "./components/Section1";
import { Section2 } from "./components/Section2";
import { Section3 } from "./components/Section3";
import { Section4 } from "./components/Section4";
import { Footer } from "./components/Footer";

// Context
import { AppContextProvider } from "./context/AppContext";

function App() {
  return (
    <div className="flex flex-col bg-zinc-800 w-screen h-screen text-white overflow font-semibold overflow-x-hidden">
      <AppContextProvider>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Footer />
      </AppContextProvider>
    </div>
  );
}

export default App;
