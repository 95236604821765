// Components
import { Timer } from "./Timer";

export const Section1 = () => {
  const offerUrl =
    "https://go.aff.7k-partners.com/vfihopkm?utm_campaign=twitter-lp-02";

  return (
    <section className="flex flex-col items-center w-full">
      <div className="flex flex-col w-screen bg-[url('https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/e36a4c88-9751-4dce-1764-688392506f00/public')] bg-cover min-h-[659px] items-center pb-10">
        <a href={offerUrl} id="offer">
          <img
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/0cae3a0f-73f3-48d8-28b0-933ac7e0ed00/public"
            alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/0cae3a0f-73f3-48d8-28b0-933ac7e0ed00/public"
            className="mt-12 w-full max-w-[269px] max-h-[80px] h-full"
          />
        </a>

        <span className="mt-10 mb-4 max-w-[90%] xl:max-w-[30%] lg:max-w-[50%] md:max-w-[90%] sm:max-w-[90%] w-full text-[1.45rem] xl:text-[2.15rem] lg:text-[2rem] md:text-[1.875rem] sm:text-[1.45rem] text-center uppercase font-extrabold leading-7 xl:leading-[2.75rem] lg:leading-7 md:leading-7 sm:leading-7">
          PARA OS PRÓXIMOS{" "}
          <span className="text-5xl text-red-200 text-red-400 text-red-600">
            <Timer playerRemaining={30} expiryTimestamp={new Date()} />
          </span>{" "}
          NOVOS JOGADORES DEPOSITANTES, VAMOS LIBERAR ATÉ...
        </span>

        <img
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/3335e56b-aa78-4543-af21-999cc7745a00/public"
          alt="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/3335e56b-aa78-4543-af21-999cc7745a00/public"
          className="display-block w-full h-full mt-4 mb-8 w-[310px] xl:w-[550px] lg:w-[480px] md:w-[400px] sm:w-[320px] mt-[-1.5rem]"
        />

        <span className="max-w-[90%] xl:max-w-[30%] lg:max-w-[50%] md:max-w-[90%] sm:max-w-[90%] w-full text-[1.45rem] xl:text-[2.15rem] lg:text-[2rem] md:text-[1.875rem] sm:text-[1.45rem] text-center uppercase font-extrabold leading-7 xl:leading-[2.75rem] lg:leading-7 md:leading-7 sm:leading-7 mt-[-2rem] mb-[2rem]">
          RODADAS GRÁTIS
        </span>

        <a href={offerUrl} id="offer">
          <button className="mt-4 text-center cursor-pointer uppercase transition-all bg-[#0075CD] text-white font-extrabold min-h-[3.5rem] w-[16.3125rem] rounded-[10px] hover:brightness-110">
            RESGATE SEU BÔNUS
          </button>
        </a>

        <span className="text-[0.875rem] mt-4">
          O cadastro é gratuito e demora menos de 1 minuto
        </span>
      </div>
    </section>
  );
};
